import React from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";

import Redirect from "./Redirect";

const Routes = () => {
  return (
    <ReactRoutes>
      <Route
        path="/success/:url/:order_id"
        element={<Redirect type="success" />}
      />
      <Route path="/cancel/:url" element={<Redirect type="cancel" />} />
    </ReactRoutes>
  );
};

export default Routes;
