import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import React from "react";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { useLocation, useParams } from "react-router-dom";

import cancel from "./lottie/cancel.json";
import success from "./lottie/success.json";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Redirect = ({ type }) => {
  let query = useQuery();
  let { url, order_id } = useParams();

  const [redirectUrl, setRedirectUrl] = React.useState("");
  const [orderId, setOrderId] = React.useState("");
  const [universalLink, setUniversalLink] = React.useState("");

  React.useEffect(() => {
    setRedirectUrl(decodeURI(query.get("d")));
    setOrderId(query.get("order_id"));
  }, [query]);

  React.useEffect(() => {
    setRedirectUrl(decodeURI(url));
    setOrderId(order_id);
  }, [url, order_id]);

  React.useEffect(() => {
    setUniversalLink(`${redirectUrl}?order_id=${orderId}`);
  }, [redirectUrl, orderId]);

  return (
    <Box
      position={"relative"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={1}
    >
      <Grid container>
        <Grid item container justifyContent={"center"}>
          <Typography
            variant="h5"
            fontWeight={700}
            textAlign="center"
            margin={6}
          >
            {type == "success" ? "Payment Successful" : "Checkout Cancelled"}
          </Typography>
        </Grid>
        <Grid item container justifyContent={"center"}>
          <Lottie
            animationData={type == "success" ? success : cancel}
            play
            loop={false}
            style={{ width: 150, height: 150 }}
          />
        </Grid>
        <Grid item container alignItems={"center"} justifyContent={"center"}>
          <Box>
            <Box marginTop={4} display={"flex"} justifyContent="center">
              <Button
                component={Link}
                variant="contained"
                size="large"
                href={universalLink}
              >
                {type == "success" ? "View Order" : "Return to App"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Redirect;
